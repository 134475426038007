import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`

  ${vars.desktop.mediaQuery} {
    &.list {
      flex: 0 0 565px;
    }

    &.grid {
      padding: 23px 0 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 5px;
      grid-row-gap: 5px;
    }

    &.grid .box {
      margin: 12px 18px;
    }
  }

  ${vars.mobile.mediaQuery} {
    /* styling */
  }
`;

export { Root };
