import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  & {
    position: relative;
  }

  .ring {
    position: absolute;
    z-index: -1;
  }

  ${vars.desktop.mediaQuery} {
    .mobile-app {
      width: 560px;
      margin-top: 35px;
      margin-right: -65px;
    }

    .ring {
      top: -133px;
      right: -350px;
      width: 1080px;
      height: 1190px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      text-align: center;
    }

    .mobile-app {
      width: calc(100% - 80px);
      margin-top: 50px;
    }

    .ring {
      top: -6%;
      left: 50%;
      margin-left: -73%;
      width: 145%;
    }
  }
`;

export { Root };
