import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  & {
    position: relative;
    background: ${vars.colors.marineBlue};
    background: linear-gradient(180deg, ${vars.colors.marineBlue} 20%, ${vars.colors.background} 80%);
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 10px;
  }

  &:before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -2px;
    position: absolute;
    border-radius: inherit;
    background: rgb(36,41,107);
    background: linear-gradient(280deg, ${vars.colors.purple} 0%, ${vars.colors.purpleOpac} 100%);
    z-index: -1;
  }

  &.left img {
    top: 50%;
    position: absolute;
  }

  &.left h3 {
    margin-bottom: 5px;
  }

  h3 {
    margin-bottom: 7px;
    letter-spacing: 0.5px;
  }

  p {
    color: #AAA;
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-bottom: 12px;
      padding: 35px 30px;
    }

    &.left {
      padding: 30px;
      padding-left: 135px;
    }

    &.left img {
      margin-top: -24px;
      left: 45px;
      width: 48px;
    }

    &.clean {
      margin-top: -30px;
      background: none;
      border: none;
    }

    &.clean:before {
      background: none;
    }

    h3 {
      font-size: 0.95rem;
    }

    p {
      font-size: 0.775rem;
      line-height: 200%;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 15px;
      padding: 30px;
    }

    img {
      width: 42px;
    }

    &.left {
      padding: 28px 25px 25px;
      padding-left: 85px;
    }

    &.left img {
      margin-top: -28px;
      left: 24px;
      width: 38px;
    }
  }
`;

export { Root };
