import styled from 'styled-components';

import vars from 'variables';

import border from 'assets/Common/Borders/horizontal.svg';

const Root = styled.div`
  .title {
    text-align: center;
    background: url(${border}) no-repeat bottom center;
    background-size: contain;
  }

  ${vars.desktop.mediaQuery} {
    .title {
      margin-top: 115px;
      margin-bottom: -45px;
      padding-bottom: 14px;
      font-size: 1.7rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    .title {
      margin-top: 80px;
      margin-bottom: -20px;
      padding-bottom: 10px;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 140%;
    }
  }
`;

export { Root };
