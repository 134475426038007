import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  p {
    color: ${vars.colors.white};
  }

  div span {
    display: flex;
    justify-content: center;
  }

  div span img {
    filter: brightness(0) invert(1);
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-top: 100px;
    }

    p {
      padding-bottom: 12px;
    }

    div {
      display: flex;
    }

    div span {
      margin-right: 50px;
    }

    img {
      width: 80%;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-top: 70px;
      text-align: center;
    }

    p {
      padding-bottom: 6px;
    }

    div {
      display: inline-flex;
    }

    div span {
      min-width: 0;
    }

    div span img {
      max-width: 60%;
    }
  }
`;

export { Root };
