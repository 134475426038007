import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  & {
    background: rgb(13, 15, 35);
  }

  li {
    text-align: center;
  }

  strong {
    display: block;
    text-transform: uppercase;
  }

  ${vars.desktop.mediaQuery} {
    ul {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      padding: 42px 0 48px;
    }

    a.button {
      margin-top: 18px;
      padding: 20px 60px;
    }
  }

  ${vars.mobile.mediaQuery} {
    ul, li {
      padding: 25px 0;
    }

    a.button {
      margin-top: 10px;
      padding-left: 35px;
      padding-right: 35px;
    }
  }
`;

export { Root };
