import React from "react";
import { Root } from "./style";

const Box = ({ className, icon, children }) => {
  const lines = (
    typeof children === "object" ? children.join("\n") : children
  ).split("\n");

  const title = lines.shift();

  return (
    <Root className={`box ${className || ""}`}>
      {icon ? <img src={icon} /> : ""}
      <h3>{title}</h3>
      <p>{lines.join("\n")}</p>
    </Root>
  );
};

export default Box;
