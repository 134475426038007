import React from "react";
import { Root } from "./style";

import { useState } from "@hookstate/core";
import { useSiteData } from "utils";

import Boxes from "components/Common/Boxes";
import SiteData from "components/Common/SiteData";

import vars from "variables";

const Taxes = () => {
  const { index } = useSiteData().pages;

  const state = useState("buy");
  const select = (category) => state.set(category);

  return (
    <Root className="body-width">
      <div className="taxInfo">
        <SiteData path="index.taxesInfo" />
        <a
          className="button blue logo"
          href={vars.links.buy}
          target="_blank"
          rel="noreferrer"
        >
          Buy HODL Now
        </a>
        <ul className="mobile">
          <li
            className={state.get() === "buy" ? "selected" : ""}
            onClick={() => select("buy")}
          >
            {index.buyTaxHeading}
          </li>
          <li
            className={state.get() === "sell" ? "selected" : ""}
            onClick={() => select("sell")}
          >
            {index.sellTaxHeading}
          </li>
          <li
            className={state.get() === "transfer" ? "selected" : ""}
            onClick={() => select("transfer")}
          >
            {index.transferTaxHeading}
          </li>
        </ul>
      </div>
      <Boxes
        name="buy"
        layout="list"
        className={state.get() === "buy" ? "visible" : ""}
        content={index.buyTaxes}
      />
      <Boxes
        name="sell"
        layout="list"
        className={state.get() === "sell" ? "visible" : ""}
        content={index.sellTaxes}
      />
      <Boxes
        name="transfer"
        layout="list"
        className={state.get() === "transfer" ? "visible" : ""}
        content={index.transferTaxes}
      />
    </Root>
  );
};

export default Taxes;
