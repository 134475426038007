import React from 'react';
import { Root } from './style';

import inc from 'assets/Index/CallToAction/FeaturedBy/inc.svg';
import benzinga from 'assets/Index/CallToAction/FeaturedBy/benzinga.svg';
import businessInsider from 'assets/Index/CallToAction/FeaturedBy/business-insider.svg';
import yahoo from 'assets/Index/CallToAction/FeaturedBy/yahoo.svg';

const FeaturedBy = () => {
  return (
    <Root>
      <p>Featured by</p>
      <div>
        <span><img src={yahoo}/></span>
        <span><img src={benzinga}/></span>
        <span><img src={businessInsider}/></span>
        <span><img src={inc}/></span>
      </div>
    </Root>
  );
};

export default FeaturedBy;
