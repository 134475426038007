import React from 'react';
import { Root } from './style';

import CommonRoadmap from 'components/Common/Roadmap';
import SiteData from 'components/Common/SiteData';

const Roadmap = () => {
  return (
    <Root className="body-width">
      <h3 className="title">
        <SiteData path="index.roadmapTitle"/>
      </h3>
      <CommonRoadmap/>
    </Root>
  );
};

export default Roadmap;
