import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  padding-top: 20px;

  .right {
    margin-left: auto;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding-bottom: 125px;
      display: flex;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      padding-bottom: 30px;
    }
  }
`;

export { Root };
