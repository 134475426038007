import React from 'react';
import { Root } from './style';

import mobileApp from 'assets/Index/CallToAction/MobileApp/mobile-app.png';
import ring1 from 'assets/Index/CallToAction/MobileApp/ring.png';

const MobileApp = () => {
  return (
    <Root>
      <img className="mobile-app" src={mobileApp}/>
      <img className="ring" src={ring1}/>
    </Root>
  );
};

export default MobileApp;
