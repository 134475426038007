import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`

  ${vars.desktop.mediaQuery} {
    .join-the-communities {
      margin-top: 75px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .join-the-communities {
      margin-top: 10px;
    }
  }
`;

export { Root };
