import React from 'react';
import { Root } from './style';

import SiteData from 'components/Common/SiteData';

import vars from 'variables';

const Exchanges = ({exchanges}) => {
  return (
    <Root className="body-width">
      <div className="wrapper">
        <section className="copy">
          <SiteData path="index.exchanges"/>
        </section>
        <section className="logos">
        {exchanges.map(exchange =>
          <div key={exchange.id} style={{backgroundImage: `url(${vars.assetsPrefix}/${exchange.logo})`}}/>
        )}
        </section>
      </div>
    </Root>
  );
};

export default Exchanges;
