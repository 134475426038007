import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  background: ${vars.colors.marineBlue};

  li label {
    font-weight: bold;
    text-transform: uppercase;
  }

  li span {
    padding-top: 2px;
    line-height: 15px;
    display: block;
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-bottom: 100px;
    }

    ul {
      padding: 70px 0;
      display: flex;
      justify-content: space-between;
    }

    li label {
      font-size: 0.775rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 40px;
    }

    ul {
      padding: 32px 0 2px;
    }

    li {
      padding-bottom: 28px;
      display: flex;
    }

    li label {
      padding-top: 1px;
      font-size: 0.75rem;
      letter-spacing: 0.25px;
    }

    li span {
      margin-left: auto;
      font-size: 0.86rem;
    }
  }
`;

export { Root };
