import styled from 'styled-components';

import vars from 'variables';

import border from 'assets/Common/Borders/horizontal.svg';

const Root = styled.div`
  .raw-html {
    text-align: center;
    background: url(${border}) no-repeat bottom left;
    background-size: contain;
  }

  .raw-html p {
    padding: 15px 0 25px;
  }

  a.button {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  ${vars.desktop.mediaQuery} {
    .raw-html h3 {
      font-size: 1.75rem;
    }

    .raw-html p {
      width: 850px;
      margin: 0 auto;
      color: ${vars.colors.grey};
    }

    a.button {
      margin-bottom: 115px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-top: 60px;
    }

    .raw-html h3 {
      font-size: 1.15rem;
    }

    a.button {
      margin-top: 30px;
      margin-bottom: 80px;
    }
  }
`;

export { Root };
