import styled from 'styled-components';

import vars from 'variables';

import border from 'assets/Common/Borders/horizontal.svg';

const Root = styled.div`
  & {
    margin-top: 100px;
    background: ${vars.colors.marineBlue};
    border-radius: 15px;
  }

  section.logos div {
    height: 24px;
    background: no-repeat center center;
    background-size: contain;
    filter: brightness(0) invert(1);
  }

  ${vars.desktop.mediaQuery} {
    .wrapper {
      padding: 80px 90px;
      display: flex;
    }

    section {
      flex: 1;
    }

    section.copy {
      padding-right: 75px;
      flex-grow: 0.8;
    }

    section.logos {
      padding-left: 15px;
      display: grid;
      grid-gap: 5rem;
      grid-template-columns: auto auto auto;
    }

    h3 {
      font-size: 1.7rem;
      font-weight: 600;
    }

    p {
      width: 400px;
      margin-top: 12px;
      font-size: 1rem;
      line-height: 150%;
    }

    .button {
      margin-top: 30px;
      margin-bottom: -10px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 40px;
    }

    .wrapper {
      padding: 40px 25px 10px;
    }

    h3 {
      width: 250px;
      margin-bottom: 10px;
      font-size: 1.275rem;
      line-height: 150%;
    }

    p {
      color: ${vars.colors.grey};
    }

    .copy div {
      text-align: center;
    }

    .button {
      margin: 0 auto;
      margin-top: 25px;
      padding-left: 35px;
      padding-right: 35px;
    }

    section.logos {
      margin-top: 20px;
    }

    section.logos div {
      height: 75px;
      background-size: auto 25px;
    }

    section.logos div:after {
      content: "";
      height: 2px;
      display: block;
      background: url(${border}) no-repeat top center;
      background-size: contain;
    }

    section.logos div:first-child:after {
      background: none;
    }
  }
`;

export { Root };
