import styled from 'styled-components';

import vars from 'variables';

import logo from 'assets/Common/Logo/hodl.svg';
import bnb from 'assets/Index/Calculator/bnb.svg';
import hodl from 'assets/Index/Calculator/hodl.svg';
import usd from 'assets/Index/Calculator/usd.svg';

const Root = styled.div`
  .calculator {
    background: rgb(15, 18, 36);
    border: 2px solid rgb(68, 70, 85);
    border-radius: 10px;
  }

  .callToAction {
    background: url(${logo}) no-repeat;
  }

  .callToAction h3 {
    font-weight: 600;
  }

  .callToAction div {
    padding-top: 8px;
    color: ${vars.colors.grey};
  }

  .callToAction strong {
    color: ${vars.colors.blue};
  }

  ul.input,
  ul.output {
    flex-grow: 1;
  }

  ul.input input {
    width: 215px;
    padding-right: 0;
  }

  li {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    border: 2px solid rgb(66, 68, 121);
    border-radius: 8px;
  }

  li:last-child {
    margin-bottom: 0;
  }

  li label {
    font-weight: bold;
    text-transform: uppercase;
  }

  li input,
  li span {
    margin-left: auto;
  }

  li strong {
    align-self: stretch;
    border-left: 2px solid rgb(66, 68, 121);
    background: no-repeat left 17px center;
  }

  li strong.bnb {
    background-image: url(${bnb});
  }

  li strong.usd {
    background-image: url(${usd});
  }

  li strong.hodl {
    background-image: url(${hodl});
  }

  li input {
    width: 175px;
    color: white;
    font-family: Jost;
    text-align: right;
    background: none;
    border: 0;
  }

  li span {
    padding-right: 15px;
    text-align: right;
    text-transform: uppercase;
  }

  li span.bnb {
    background: url(${bnb}) no-repeat center right 15px;
  }

  ${vars.desktop.mediaQuery} {
    .calculator {
      margin: 35px;
      padding: 35px;
      display: flex;
    }

    .callToAction {
      width: 350px;
      padding-top: 50px;
      padding-right: 40px;
      background-size: 92px;
    }

    .callToAction h3 {
      font-size: 1.7rem;
      line-height: 120%;
    }

    .callToAction div {
      line-height: 165%;
    }

    ul.output {
      padding-left: 30px;
    }

    ul.output span {
      width: 200px;
    }

    ul.output span.days {
      width: 115px;
    }

    li label {
      padding-top: 1px;
      padding-left: 18px;
      font-size: 0.75rem;
      line-height: 525%;
    }

    li strong {
      width: 70px;
      margin-left: 18px;
      padding-left: 57px;
      font-size: 0.9rem;
      line-height: 63px;
    }

    li span {
      width: 100px;
      font-size: 0.9rem;
    }

    li span.bnb {
      padding-right: 52px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .calculator {
      padding: 35px 27px 27px;
    }

    .callToAction {
      padding-top: 36px;
      text-align: center;
      background-position: top center;
      background-size: 80px;
    }

    .callToAction h3 {
      font-size: 1.25rem;
      line-height: 150%;
    }

    .callToAction .raw-html {
      padding-bottom: 27px;
    }

    ul.input,
    li {
      margin-bottom: 10px;
    }

    ul.output span {
      width: 145px;
    }

    ul.output span.days {
      width: 115px;
    }

    li {
      border-width: 1px;
    }

    li label {
      padding-top: 1px;
      padding-left: 15px;
      font-size: 0.625rem;
      line-height: 415%;
    }

    li input,
    li span {
      font-size: 0.675rem;
    }

    li strong {
      width: 64px;
      margin-left: 10px;
      padding-left: 33px;
      font-size: 0.625rem;
      line-height: 432%;
      background-size: 16px auto;
      background-position: center left 10px;
    }

    li span.bnb {
      padding-right: 36px;
      background-size: 16px auto;
      background-position: center right 13px;
    }
  }
`;

export { Root };
