import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`

  ${vars.desktop.mediaQuery} {
    .paginator {
      display: none;
    }
  }

  ${vars.mobile.mediaQuery} {
    .paginator {
      margin-top: 60px;
      margin-bottom: 50px;
      display: flex;
      position: relative;
      text-align: center;
      background: rgb(30, 33, 50);
      background: linear-gradient(270deg, rgba(30, 33, 50, 0) 10%, rgba(30, 33, 50, 1) 100%);
      border: 2px solid rgb(68, 70, 85);
      border-radius: 5px;
    }

    .paginator div {
      width: 54px;
      background: no-repeat center center;
      background-size: 8px;
    }

    .paginator span {
      flex-grow: 1;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 46px;
      text-transform: uppercase;
    }
  }
`;

export { Root };
