import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import Boxes from "components/Common/Boxes";
import SiteData from "components/Common/SiteData";

import vars from "variables";

const Features = () => {
  const { index } = useSiteData().pages;

  return (
    <Root className="body-width">
      <SiteData path="index.featuresIntro" />
      <Boxes name="features" layout="grid" content={index.features} />
      <a
        className="button blue logo"
        href={vars.links.buy}
        target="_blank"
        rel="noreferrer"
      >
        Buy HODL Now
      </a>
    </Root>
  );
};

export default Features;
