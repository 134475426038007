import React from "react";
import { Root } from "./style";

import Box from "components/Common/Box";

import antiWhale from "assets/Common/Boxes/anti-whale.svg";
import bettingGames from "assets/Common/Boxes/betting-games.svg";
import bnb from "assets/Common/Boxes/bnb.svg";
import dex from "assets/Common/Boxes/dex.svg";
import hodlHands from "assets/Common/Boxes/hodl-hands.svg";
import liquidity from "assets/Common/Boxes/liquidity.svg";
import marketing from "assets/Common/Boxes/marketing.svg";
import p2e from "assets/Common/Boxes/p2e.svg";
import reflections from "assets/Common/Boxes/reflections.svg";
import rewardPool from "assets/Common/Boxes/reward-pool.svg";
import rewardStacking from "assets/Common/Boxes/reward-stacking.svg";
import rewardsOrReinvest from "assets/Common/Boxes/rewards-or-reinvest.svg";

const Boxes = ({ name, className, layout, content }) => {
  const icons = {
    "anti-whale": antiWhale,
    "betting-games": bettingGames,
    bnb: bnb,
    dex: dex,
    "hodl-hands": hodlHands,
    liquidity: liquidity,
    marketing: marketing,
    p2e: p2e,
    reflections: reflections,
    "reward-pool": rewardPool,
    "reward-stacking": rewardStacking,
    "rewards-or-reinvest": rewardsOrReinvest,
  };

  const items = [];
  let currentItem;

  content.split(/\n/).forEach((line) => {
    const icon = line.match(/(?:=)\s?(.*)/);
    if (icon) {
      currentItem = { icon: icon[1], content: "" };
      items.push(currentItem);
    } else if (line.match(/^\s*$/)) {
      currentItem = null;
    } else if (currentItem) {
      currentItem.content += (currentItem.content ? "\n" : "") + line;
    }
  });

  return (
    <Root className={`boxes ${className} ${layout}`}>
      {items.map((item, index) => (
        <Box
          className={layout === "list" ? "left" : ""}
          icon={icons[item.icon]}
          key={`${name}-${index}`}
        >
          {item.content}
        </Box>
      ))}
    </Root>
  );
};

export default Boxes;
