import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";
import { Link } from "gatsby";
import capitalize from "lodash/capitalize";

import RawHTML from "components/Common/RawHTML";

import vars from "variables";

const HoldingNFTs = ({ showMore }) => {
  const {
    resources: {
      banners: { holdingNfts, holdingNftsLivePill },
    },
    pages: { nfts: page },
  } = useSiteData();

  let subtitle;

  if (showMore) {
    subtitle = page.subtitle;
  }

  return (
    <Root className={showMore ? "show-more" : ""}>
      <div className="body-width">
        {showMore || subtitle ? (
          showMore ? (
            <div className="highlight">
              <RawHTML html={holdingNftsLivePill} />
            </div>
          ) : (
            <h4>
              <span>{subtitle}</span>
            </h4>
          )
        ) : (
          ""
        )}
        <RawHTML html={holdingNfts} />
        {showMore ? (
          <Link className="more" to={vars.pages.nfts}>
            Learn more
          </Link>
        ) : (
          ""
        )}
      </div>
    </Root>
  );
};

export default HoldingNFTs;
