import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  & {
    padding-top: 25px;
  }

  ul {
    margin-bottom: 35px;
    padding: 2px;
    color: rgb(93, 113, 168);
    display: inline-flex;
    line-height: 240%;
    font-weight: bold;
    text-transform: uppercase;
    background: rgb(0, 42, 84);
    border-radius: 25px;
  }

  li {
    margin-right: -10px;
    cursor: pointer;
  }

  li.selected {
    color: white;
    cursor: default;
    background: ${vars.colors.blue};
    border-radius: 25px;
  }

  .taxInfo h3 {
    margin-bottom: 12px;
  }

  .taxInfo h4 {
    margin-bottom: 5px;
  }

  .taxInfo button {
    margin-top: 15px;
  }

  .boxes {
    display: none;
  }

  .boxes.visible {
    display: block;
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-bottom: -70px;
      display: flex;
    }

    ul {
      font-size: 0.8rem;
    }

    li {
      padding: 0 25px;
    }

    .taxInfo {
      padding-right: 260px;
    }

    .taxInfo h3 {
      font-size: 1.75rem;
    }

    .taxInfo h4 {
      font-size: 0.8rem;
    }

    .taxInfo p {
      margin-bottom: 35px;
      color: ${vars.colors.grey};
      line-height: 165%;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 70px;
    }

    ul {
      font-size: 0.75rem;
    }

    li {
      padding: 0 22px;
    }

    .taxInfo h3 {
      font-size: 1.25rem;
    }

    .taxInfo h4 {
      font-size: 0.7rem;
    }

    .taxInfo p {
      margin-bottom: 12px;
    }

    a.button {
      margin: 25px 0 27px;
      display: table;
    }
  }
`;

export { Root };
